<template>
  <div class="container-fluid px-xl-4 py-4 part-list-wrapper">
    <div class="part-list">
      <confirmation-dialog @close="dialogDelete = false" :dialog="dialogDelete" @confirm="deleteCarParts()" />
      <edit-part-dialog @close="dialog = false" :dialog="dialog" :type="partType" :partId="selectedPartId"
        :carProfileId="selectedCarProfileId" :partNumber="selectedPartNumber" :partName="selectedPartName" />
      <confirm-bulk-print-dialog @close="bulkPrintDialog = false" :dialog="bulkPrintDialog" :partIds="selectedPartIds"
        :startDate="printFilterDates.startDate" :endDate="printFilterDates.endDate" />
      <print-part-barcode-dialog @close="printDialog = false" :printDialog="printDialog" :printPartId="printPartId"
        :printCarProfileId="printCarProfileId" />
      <image-viewer @close="dialogImage = false" :images="partsImages" :dialog="dialogImage" />
      <b-row>
        <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12" v-if="showScanner">
          <StreamBarcodeReader @decode="onDecode" @error="onError"></StreamBarcodeReader>
        </b-col>
      </b-row>
      <div class="custom-table">
        <div class="search-container">
          <h5 class="total-parts total-parts-label"></h5>
          <div class="s-c-right">
            <div class="search-group">
            <input type="text" id="dataTableSearch" ref="dataTableSearch" />
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M19 19L14.7 14.7" stroke="black" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>

            <div class="add-btn d-flex justify-content-start flex-wrap gap-5">
          <router-link class="btn custom-btn pattern-btn" to="/add-parts"><span>Add Part</span></router-link>
          <div>
            <b-button class="btn custom-btn pattern-btn bulk-delete-icon" data-tooltip="Bulk Delete">
              <span>Delete</span>
            </b-button>
          </div>
          <div>
            <b-button class="btn custom-btn pattern-btn" data-tooltip="Bulk Print" @click="bulkPrint()">
              <span>Print</span>
            </b-button>
          </div>
        </div>
          </div>
          
        </div>
        <table id="example" class="display responsive nowrap" cellspacing="0" width="100%">
          <thead>
            <tr>
              <th class="desktop tablet-p tablet-l mobile-l mobile-p">
                <b-form-group class="mb-0">
                  <b-form-checkbox v-model="isCheckAllParts"
                    @change="onCheckAllParts()"><span>Date</span></b-form-checkbox>
                </b-form-group>
              </th>
              <!-- <th class="desktop">Date</th> -->
              <th class="desktop tablet-p tablet-l mobile-l mobile-p">
                Part Name
              </th>
              <th>Quantity</th>
              <th class="none">View</th>
              <th>Year/ Make/ Model/ Miles</th>
              <th class="none">Recommended Sales $</th>
              <th>Interchange</th>
              <th>Part Grade</th>
              <th class="none">Inventory Days</th>
              <th>Warranty</th>
              <th>Description</th>
              <th class="desktop">Actions</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import $ from "jquery";
import { mapGetters, mapActions } from "vuex";
import { ITEMS_PER_PAGE } from "@/constants/common";
import { PARTLIST } from "@/constants/apiUrls";
import { showSuccess, showFailure } from "../eventbus/action.js";
import tokenService from "./../services/token.service.js";
import ConfirmationDialog from "../components/ConfirmationDialog";
import EditPartDialog from "../components/EditPartDialog";
import ConfirmBulkPrintDialog from "../components/ConfirmBulkPrintDialog";
import PrintPartBarcodeDialog from "../components/PrintPartBarcodeDialog";
import ImageViewer from "../components/ImageViewer";
import EventBus from "@/eventbus";
export default {
  components: {
    ConfirmationDialog,
    ImageViewer,
    EditPartDialog,
    ConfirmBulkPrintDialog,
    PrintPartBarcodeDialog
  },
  data() {
    return {
      options: [
        { text: "Part Name", value: "part_name" },
      ],
      items: [],
      imgSrc: null,
      selectedItem: null,
      dialogDelete: false,
      table: null,
      selectedId: null,
      dialogImage: false,
      partsImages: [],
      selectedPartId: '',
      selectedPartNumber: '',
      selectedPartName: '',
      partType: 'PartType',
      selectedCarProfileId: '',
      dialog: false,
      printDialog: false,
      printPartId: '',
      printCarProfileId: '',
      showScanner: false,
      isCheckAllParts: false,
      selectedPartIds: [],
      bulkPrintDialog: false,
      printFilterDates: {
        startDate: new Date(),
        endDate: new Date(),
      }
    };
  },
  mounted() {
    this.getPartList();
    this.addListeners();
    EventBus.$on("resetDialog", () => {
      this.dialog = false;
      this.printDialog = false;
      this.selectedPartId = '';
      this.selectedPartNumber = '';
      this.selectedPartName = '';
      this.partType = 'PartType';
      this.selectedCarProfileId = '';
      this.printPartId = '';
      this.printCarProfileId = '';
      this.bulkPrintDialog = false;
      this.selectedPartIds = [];
      this.printFilterDates = {
        startDate: new Date(),
        endDate: new Date(),
      }
    });
    EventBus.$on("getParts", () => {
      this.table.ajax.reload();
    });

    var id = this.$route.query.id ? this.$route.query.id : '';
    if (id) {
      this.onDecode(id);
    }
  },
  computed: {
    ...mapGetters({
      partData: "partlist/getPartById",
    }),
  },
  methods: {
    ...mapActions({
      deleteParts: "partlist/deleteParts",
      deleteSelectedParts: "partlist/deleteSelectedParts",
      getPartsById: "partlist/getPartsById",
    }),

    async addListeners() {
      const self = this;
      $("#example").on("click", ".view-icon", function (e) {
        self.dialogImage = false;
        let url = "";
        if (e.currentTarget.dataset.mediaurl.length) {
          url = e.currentTarget.dataset.mediaurl.split(",");
        }
        self.partsImages = [...url];
        setTimeout(() => {
          self.dialogImage = true;
        }, 50);
        // self.$router.push({ name: "Car Detail", params: { id: Number(id) } });
        e.preventDefault();
      });

      $("#example").on("click", ".edit-icon", function (e) {
        let id = e.currentTarget.dataset.secret;
        let profileType = e.currentTarget.dataset.profiletype;
        let partNumber = e.currentTarget.dataset.part_number;
        let partName = e.currentTarget.dataset.part_name;
        let car_profile_id = e.currentTarget.dataset.profile;

        console.log('from edit listener..', profileType);
        self.editPartAction(id, profileType, partNumber, car_profile_id, partName);
        e.preventDefault();
      });

      $("#example").on("click", ".delete-icon", function (e) {
        self.dialogDelete = false;
        self.selectedId = e.currentTarget.dataset.secret;
        // self.dialogDelete = true;
        setTimeout(() => {
          self.dialogDelete = true;
        }, 50);
        console.log('delt', self.dialogDelete);
        // e.preventDefault();
      });

      $("body").on("click", ".bulk-delete-icon", function (e) {
        self.dialogDelete = false;
        setTimeout(() => {
          self.dialogDelete = true;
        }, 50);
      });

      $("#example").on("click", ".print-button", function (e) {
        let partId = e.currentTarget.dataset.secret;
        let partNumber = e.currentTarget.dataset.part_number;
        let car_profile_id = e.currentTarget.dataset.profile;
        let group_id = e.currentTarget.dataset.group_id;
        let type = e.currentTarget.dataset.type;

        self.printPartId = partId;
        self.printCarProfileId = car_profile_id;
        console.log('print..', self.printPartId, self.printCarProfileId);
        if (type === 'single') {
          self.printDialog = true;
        } else {
          self.$router.push({ name: "Print All Barcode", params: { ids: group_id, type: 'group_id' } });
        }
        e.preventDefault();
      })
    },

    reload() {
      try {
        this.$store.commit("setLoader", true);
        this.table.ajax
          .url(process.env.VUE_APP_API_HOST + PARTLIST)
          .load();
        $.fn.dataTable.ext.errMode = "throw";
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async getPartList() {
      let self = this;

      try {
        this.$store.commit("setLoader", true);
        this.table = $("#example").DataTable({
          order: [[0, 'desc']],
          ajax: {
            url: process.env.VUE_APP_API_HOST + PARTLIST,
            type: "GET",
            beforeSend: function (request) {
              request.setRequestHeader(
                "Authorization",
                `Bearer ${tokenService.getAccessToken()}`
              );
            },
          },
          drawCallback: function (settings) {

            $(".total-parts").html(
              `Total Parts <span>${settings.json.recordsTotal}</span>`
            );
          },
          columns: [
            {
              orderable: false,
              data: function (row) {
                return (`<form-group class="select-checkbox custom-control custom-control-inline custom-checkbox">
                            <input type="checkbox" 
                              value="${row.id}" 
                              name="selectedPartIds[]" 
                              class="custom-control-input"
                              id="checkbox-${row.id}"/>
                            <label for="checkbox-${row.id}" class="custom-control-label"></label>
                          </form-group><span class="collespe-wrap">${self.$options.filters.DateZoneFilter(
                    moment(row.created_at)
                  ) || "N/A"}</span>`);
              },
            },
            // {
            //   data: function (row) {
            //     return (
            //       self.$options.filters.DateZoneFilter(
            //         moment(row.created_at)
            //       ) || "N/A"
            //     );
            //   },
            // },
            {
              data: function (full) {
                return full.part_name || "N/A";
              },
            },
            {
              data: function (row) {
                return (
                  row.group_count > 0 ? row.group_checkout_count + '/' + (row.group_count + row.group_checkout_count) : 1
                );
              },
            },
            {
              data: function (full) {
                return full.view_count || "0";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return (
                  full.year +
                  "/" +
                  full.make +
                  "/" +
                  full.model +

                  (full.miles ? "/" + (
                    Number(full.miles)
                      .toFixed(0)
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                  ) : '')
                );
              },
            },
            {
              data: function (row) {
                return (
                  "$ " +
                  Number(row.recommended_sale_price)
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                );
              },
              orderable: false,
            },
            {
              orderable: false,
              data: function (full) {
                return full.interchange_code || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.part_grade || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.inventory_days || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.warranty_days || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.description
                  ? `<span class="description" title=${full.description}>${full.description}</span>`
                  : "N/A";
              },
            },
            {
              className: "action",
              orderable: false,
              data: function (row) {
                return `<ul class="action-list">
                <li>
                  <a
                    class="edit-btn act-icon view-icon tooltips"
                    data-tooltip="Part Images"
                    data-secret = ${row.id}
                    data-mediaurl = ${row.medias.map((val) => val.url500)}
                  >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 13L5.586 8.414C5.96106 8.03906 6.46967 7.82843 7 7.82843C7.53033 7.82843 8.03894 8.03906 8.414 8.414L13 13M11 11L12.586 9.414C12.9611 9.03906 13.4697 8.82843 14 8.82843C14.5303 8.82843 15.0389 9.03906 15.414 9.414L17 11M11 5H11.01M3 17H15C15.5304 17 16.0391 16.7893 16.4142 16.4142C16.7893 16.0391 17 15.5304 17 15V3C17 2.46957 16.7893 1.96086 16.4142 1.58579C16.0391 1.21071 15.5304 1 15 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V15C1 15.5304 1.21071 16.0391 1.58579 16.4142C1.96086 16.7893 2.46957 17 3 17Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                  </a>
                </li>
                <li>
                  <router-link
                    class="edit-btn act-icon edit-icon edit-con-${row.id} tooltips"
                    data-tooltip="Edit"
                    data-secret = ${row.id}
                    data-profiletype = ${row.profile_type}
                    data-part_number = ${row.part_number}
                    data-part_name = "${row.part_name}"
                    data-profile = ${row.car_profile_id}
                    :to="{ name: 'Edit Part', params: { id: item.id } }"
                    ><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.57012 3.88944L10.1106 6.42989M9.20334 1.6514L2.33082 8.52392C1.97572 8.87853 1.73354 9.33032 1.63481 9.82235L1 13L4.17766 12.364C4.66966 12.2656 5.12087 12.0244 5.47608 11.6692L12.3486 4.79666C12.5551 4.59014 12.7189 4.34496 12.8307 4.07513C12.9425 3.8053 13 3.51609 13 3.22403C13 2.93197 12.9425 2.64276 12.8307 2.37293C12.7189 2.1031 12.5551 1.85792 12.3486 1.6514C12.1421 1.44488 11.8969 1.28106 11.6271 1.16929C11.3572 1.05753 11.068 1 10.776 1C10.4839 1 10.1947 1.05753 9.92487 1.16929C9.65504 1.28106 9.40986 1.44488 9.20334 1.6514Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                  </router-link>
                </li>
                <li>
                  <a
                    @click="
                      selectedItem = item.id;
                      deleteItemConfirm();
                    "
                    data-secret = ${row.id}
                    class="edit-btn act-icon delete-icon tooltips"
                    data-tooltip="Delete"
                  >
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 5H21M9 10V15M13 10V15M3 5H19L17.42 19.22C17.3658 19.7094 17.1331 20.1616 16.7663 20.49C16.3994 20.8184 15.9244 21 15.432 21H6.568C6.07564 21 5.60056 20.8184 5.23375 20.49C4.86693 20.1616 4.63416 19.7094 4.58 19.22L3 5ZM6.345 2.147C6.50675 1.80397 6.76271 1.514 7.083 1.31091C7.4033 1.10782 7.77474 0.999996 8.154 1H13.846C14.2254 0.999806 14.5971 1.10755 14.9176 1.31064C15.2381 1.51374 15.4942 1.80381 15.656 2.147L17 5H5L6.345 2.147Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                  </a>
                </li>
                <li>
                  <a
                    data-profiletype = ${row.profile_type}
                    data-part_number = ${row.part_number}
                    data-profile = ${row.car_profile_id}
                    data-secret = ${row.id}
                    data-group_id = ${row.group_id}
                    data-type = ${row.group_count + row.group_checkout_count > 1 ? 'group_id' : 'single'}
                    class="edit-btn act-icon print-icon print-button tooltips"
                    data-tooltip="Print"
                  >
                   <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0611 10.0611H11.3556C11.6989 10.0611 12.0281 9.92473 12.2709 9.68198C12.5136 9.43922 12.65 9.10997 12.65 8.76667V6.17778C12.65 5.83447 12.5136 5.50522 12.2709 5.26247C12.0281 5.01971 11.6989 4.88333 11.3556 4.88333H2.29444C1.95114 4.88333 1.62189 5.01971 1.37913 5.26247C1.13638 5.50522 1 5.83447 1 6.17778V8.76667C1 9.10997 1.13638 9.43922 1.37913 9.68198C1.62189 9.92473 1.95114 10.0611 2.29444 10.0611H3.58889M10.0611 4.88333V2.29444C10.0611 1.95114 9.92473 1.62189 9.68198 1.37913C9.43922 1.13638 9.10997 1 8.76667 1H4.88333C4.54003 1 4.21078 1.13638 3.96802 1.37913C3.72527 1.62189 3.58889 1.95114 3.58889 2.29444V4.88333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.58887 8.76661C3.58887 8.4233 3.72525 8.09406 3.968 7.8513C4.21076 7.60855 4.54 7.47217 4.88331 7.47217H8.76664C9.10995 7.47217 9.4392 7.60855 9.68195 7.8513C9.92471 8.09406 10.0611 8.4233 10.0611 8.76661V11.3555C10.0611 11.6988 9.92471 12.0281 9.68195 12.2708C9.4392 12.5136 9.10995 12.6499 8.76664 12.6499H4.88331C4.54 12.6499 4.21076 12.5136 3.968 12.2708C3.72525 12.0281 3.58887 11.6988 3.58887 11.3555V8.76661Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                  </a>
                </li>
              </ul>`;
              },
            },
          ],
          pagingType: "simple_numbers",
          pageLength: ITEMS_PER_PAGE,
          serverSide: true,
          processing: false,
          dom: "Bfrtip",
          language: {
            search: '',
            searchPlaceholder: "Search..."
          },
          buttons: [
            { extend: "copy", text: "Copy to clipboard" },
            { extend: "Back", text: "Export to excel" },
            //"copy",
            "csv",
            "print",
          ],
        });

        // this.table.on("click", "th.select-checkbox", function() {


        //   }).on("select deselect", function() {
        //     ("Some selection or deselection going on")
        //     if (this.table.rows({
        //             selected: true
        //         }).count() !== this.table.rows().count()) {
        //         $("th.select-checkbox").removeClass("selected");
        //     } else {
        //         $("th.select-checkbox").addClass("selected");
        //     }
        //   });
        $.fn.dataTable.ext.errMode = "throw";
        // Move the search input to the custom input
        const searchInput = $(this.table.table().container()).find('.dataTables_filter input');
        $(this.$refs.dataTableSearch).replaceWith(searchInput);
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    onCheckAllParts() {
      if (this.isCheckAllParts) {
        $("th .custom-control-input").addClass("selected");
        $(".select-checkbox input[type=checkbox]").each(function () { this.checked = true; });
      } else {
        $(".select-checkbox input[type=checkbox]").each(function () { this.checked = false; });
        $("th .custom-control-input").removeClass("selected");
      }
    },
    async deleteCarParts() {
      try {
        this.$store.commit("setLoader", true);
        this.selectedPartIds = await ($(".select-checkbox input[type=checkbox]:checked").map(function (i, checkbox) { return checkbox.value; })).toArray();
        if (this.selectedId) {
          await this.deleteParts(this.selectedId);
        } else if (this.selectedPartIds.length > 0) {
          await this.deleteSelectedParts({ ids: this.selectedPartIds });
        } else {
          this.dialogDelete = false;
          showFailure("Please select atleast one part to delete");
          return;
        }
        this.table.ajax.reload();
        this.selectedPartIds = [];
        this.isCheckAllParts = false;
        this.dialogDelete = false;
        this.selectedId = null;
        showSuccess("Car Parts deleted Successfully");
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async bulkPrint() {
      this.selectedPartIds = await ($(".select-checkbox input[type=checkbox]:checked").map(function (i, checkbox) { return checkbox.value; })).toArray();
      this.bulkPrintDialog = true;
    },
    editPartAction(id, profileType, partNumber, car_profile_id, partName) {
      if (profileType == 'auction') {
        this.selectedPartId = id;
        this.selectedPartNumber = partNumber;
        this.selectedPartName = partName;
        this.partType = '';
        this.selectedCarProfileId = car_profile_id;
        console.log('from edit if..', this.selectedPartId, this.selectedCarProfileId);

        this.dialog = true;

      } else {
        this.$router.push({
          name: "Edit Part",
          params: { uid: Number(id) },
        });
      }
    },

    async getPartById(partId) {
      try {
        this.$store.commit("setLoader", true);
        await this.getPartsById(partId);
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async onDecode(result) {
      await this.getPartById(result);
      let id = result;
      let profileType = this.partData.profile_type;
      let partNumber = this.partData.part_number;
      let partName = this.partData.part_name;
      let car_profile_id = (this.partData.car_profile_id).toString();
      this.editPartAction(id, profileType, partNumber, car_profile_id, partName);
      this.onReader = false;
    },
    onError(error) {
      console.log(error);
    },
  },
};
</script>
